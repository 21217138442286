@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}



.btn-base {
  @apply bg-black text-white rounded-lg font-medium h-[44px];
}

.btn-xl {
  @apply bg-black text-white rounded-lg font-medium w-[170px] h-[44px];
}

.btn-outline {
  @apply text-black hover:text-white hover:bg-black rounded-lg text-sm font-medium h-[42px] px-6 border border-black duration-300 disabled:cursor-not-allowed;
}

.btn-outline-sm {
  @apply bg-white text-black rounded-lg text-sm font-medium duration-300 h-[42px] px-6 border border-black;
}

.btn-white-xl {
  @apply bg-white text-black rounded-lg font-semibold w-[170px] h-[44px];
}

.link {
  @apply text-start hover:text-red-600 duration-300;
}

/* steps form */

.formControl-checkbox [type="checkbox"],
.formControl-radiobutton [type="radio"] {
  display: none;
}

/* --- */

/* keen-slider */

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

/*  */
