/* OrderDetailMeter.css */

.order-detail-meter-container {
    overflow-x: auto;
    padding: 16px;
    font-family: 'Lato', sans-serif;
  }
  
  .order-detail-meter {
    background-color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    overflow: hidden;
  }
  
  .stat-group {
    padding: 16px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .stat-item {
    flex: 1 1 100%;
    max-width: 100%;
    text-align: center;
    margin: 8px;
  }
  
  .status-text {
    font-size: 12px;
  }
  
  @media (min-width: 640px) {
    .stat-item {
      flex: 1 1 calc(33.3333% - 16px);
      max-width: calc(33.3333% - 16px);
    }
  
    .status-text {
      font-size: 14px;
    }
  }
  
  @media (min-width: 768px) {
    .stat-item {
      flex: 1 1 calc(20% - 16px);
      max-width: calc(20% - 16px);
    }
  }
  
  @media (min-width: 1024px) {
    .stat-item {
      flex: 1 1 calc(16.6667% - 16px);
      max-width: calc(16.6667% - 16px);
    }
  }
  